export default [
    {
        header: "image-tools",
        links: [
            {
                title: "image-resizer",
                link: "/",
            },
            {
                title: "bulk-image-resizer",
                link: "/bulk-resize",
            },
            {
                title: "image-compressor",
                link: "/image-compressor",
            },
            {
                title: "crop-image",
                link: "/crop-image",
            },
            {
                title: "collage-maker",
                link: "https://www.photojoiner.net/features/collage-maker",
            },
            {
                title: "flip-image",
                link: "/flip-image",
            },
            {
                title: "rotate-image",
                link: "/rotate-image",
            },
            {
                title: "image-enlarger",
                link: "/image-enlarger",
            },
            {
                title: "color-picker",
                link: "/color-picker",
            },
            {
                title: "meme-generator",
                link: "/meme-generator",
            },
        ],
    },
    {
        header: "image-converters",
        links: [
            {
                title: "pdf-to-jpg",
                link: "https://www.freeconvert.com/pdf-to-jpg",
            },
            {
                title: "heic-to-jpg",
                link: "https://www.freeconvert.com/heic-to-jpg",
            },
            {
                title: "svg-converter",
                link: "https://www.freeconvert.com/svg-converter",
            },
            {
                title: "pdf-to-png",
                link: "https://www.freeconvert.com/pdf-to-png",
            },
            {
                title: "png-to-svg",
                link: "https://www.freeconvert.com/png-to-svg",
            },
            {
                title: "webp-to-jpg",
                link: "https://www.freeconvert.com/webp-to-jpg",
            },
            {
                title: "png-to-jpg",
                link: "https://www.freeconvert.com/png-to-jpg",
            },
            {
                title: "jpg-to-png",
                link: "https://www.freeconvert.com/jpg-to-png",
            },
        ],
    },
    {
        header: "pdf-tools",
        links: [
            {
                title: "compress-pdf",
                link: "https://www.freeconvert.com/compress-pdf",
            },
            {
                title: "pdf-converter",
                link: "https://www.freeconvert.com/pdf-converter",
            },
            {
                title: "image-to-pdf",
                link: "https://www.freeconvert.com/convert/image-to-pdf",
            },
            {
                title: "jpg-to-pdf",
                link: "https://www.freeconvert.com/jpg-to-pdf",
            },
            {
                title: "png-to-pdf",
                link: "https://www.freeconvert.com/png-to-pdf",
            },
            {
                title: "pdf-to-gif",
                link: "https://www.freeconvert.com/pdf-to-gif",
            },

        ],
    },
    {
        header: "apps",
        links: [
            {
                title: "android-image-converter",
                link: "https://play.google.com/store/apps/details?id=com.freeconvert.image_converter",
            },
            {
                title: "ios-image-converter",
                link: "https://apps.apple.com/us/app/image-converter-pixconverter/id1570076731",
            },
            {
                title: "android-collage-maker",
                link: "https://play.google.com/store/apps/details?id=com.freeconvert.collagemaker",
            },
            {
                title: "ios-collage-maker",
                link: "https://apps.apple.com/us/app/collage-maker-photojoiner/id1587802950",
            },
            {
                title: "android-video-compressor-app",
                link: "https://play.google.com/store/apps/details?id=com.freeconvert.video_compressor",
            },
            {
                title: "ios-video-compressor-app",
                link: "https://apps.apple.com/ca/app/video-compress-shrinkvid/id1560444951",
            },
        ],
    },
    {
        header: "about",
        links: [
            {
                title: "contact-us",
                link: "/contact-us",
            },
            {
                title: "imprint",
                link: "/about",
            },
            {
                title: "privacy",
                link: "/privacy",
            },
            {
                title: "terms",
                link: "/terms-of-service",
            },
        ],
    },
];
