import * as React from "react";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/shadcn/carousel";
import {useRouter} from "next/router";
import Link from "next/link";

interface IReview {
    name: string;
    rating: number;
    title: string;
    details: string;
}

const list: IReview[] = [
    {
        name: "Burnt Noodles",
        rating: 5,
        title: "VERY VERY efficient to use",
        details:
            "very VERY efficient to use, no boring ads, all that annoying stuff\n" +
            "there's like a million different tools to use, you can resize images (and you can resize them in bulk!), compressing images, cropping, flipping, rotating, enlarging, you name it!!! not only that, but you can also change the files itself! like from PNG to JPG, PNG to SVG, etc etc. there's even an app for the website itself available on android!!!",
    },
    {
        name: "Thomas Humphreys",
        rating: 5,
        title: "Great service",
        details:
            "I thought it would be difficult to get the photo to the requirements, but this image resizer quickly and efficently got the photo to the needed dimensions and size.",
    },
    {
        name: "Blake",
        rating: 5,
        title: "Best online image resizing tool",
        details:
            "Really elegant interface and streamlined user experience, no annoying popups or paywalls. Gets the job done no matter the size (bulk resize is super nice). Best online image resizing tool, hands down!\n",
    },
    {
        name: "Wilbert Van Zelst",
        rating: 5,
        title: "Nice app",
        details:
            "Nice app. No adverts that are located on irritating locations.\n" +
            "It's an straight forward resizer without fancy things, but for most of the people exactly what you're looking for.\n" +
            'Bulk converter very easy to use. Select pictures, put them in the "drop location" and GO!\n' +
            'Download the "zip file" and there are your pictures!',
    },
];

export function TrustPilotCarousel() {
    const router = useRouter();
    function buildRating(rating: number) {
        return (
            <div className="flex flex-row">
                {Array.from({ length: rating }, (_, i) => (
                    <svg
                        key={i}
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="18" height="18" transform="translate(0.5 0.196777)" fill="#00B67A" />
                        <path
                            d="M15.7888 7.78243H10.9659L9.48129 3.19678L7.98788 7.78243L3.16504 7.77364L7.06548 10.6111L5.57207 15.1968L9.47251 12.3593L13.3729 15.1968L11.8883 10.6111L15.7888 7.78243Z"
                            fill="white"
                        />
                        <path d="M12.2223 11.6479L11.8885 10.6113L9.48145 12.3595L12.2223 11.6479Z" fill="#00B67A" />
                    </svg>
                ))}
            </div>
        );
    }

    return (
        <Carousel
            className="w-44 xs:w-56 sm:w-72 md:w-96  lg:w-full xs:mt-5 mt-20   mx-auto lg:mx-0"
            opts={{
                loop: true,
                slidesToScroll: 1,
            }}
        >
            <CarouselContent className="-ml-1">
                {list.map((review, index) => (
                    <CarouselItem key={index} className="py-8 px-5  lg:basis-1/3">
                        <Link href="https://www.trustpilot.com/review/imageresizer.com" target="_blank">
                            <div className="p-2 flex flex-col gap-3  border-b-2 pb-5">
                                <div className="flex flex-row items-center">
                                    <div className="pr-2"> {buildRating(review.rating)} </div>
                                    <div className="flex flex-row items-center">
                                        <svg
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14.5 7.19678C14.5 9.05329 13.7625 10.8338 12.4497 12.1465C11.137 13.4593 9.35652 14.1968 7.5 14.1968C5.64348 14.1968 3.86301 13.4593 2.55025 12.1465C1.2375 10.8338 0.5 9.05329 0.5 7.19678C0.5 5.34026 1.2375 3.55978 2.55025 2.24703C3.86301 0.934275 5.64348 0.196777 7.5 0.196777C9.35652 0.196777 11.137 0.934275 12.4497 2.24703C13.7625 3.55978 14.5 5.34026 14.5 7.19678ZM11.0262 4.54553C10.9637 4.48324 10.8893 4.4342 10.8074 4.40134C10.7256 4.36848 10.6379 4.35247 10.5497 4.35426C10.4614 4.35606 10.3745 4.37563 10.294 4.4118C10.2135 4.44797 10.1412 4.5 10.0813 4.56478L7.04238 8.43665L5.211 6.6044C5.0866 6.48848 4.92206 6.42537 4.75204 6.42837C4.58203 6.43137 4.41982 6.50025 4.29958 6.62048C4.17934 6.74072 4.11047 6.90293 4.10747 7.07295C4.10447 7.24296 4.16758 7.4075 4.2835 7.5319L6.59875 9.84803C6.66112 9.91028 6.73539 9.95934 6.81714 9.99227C6.89888 10.0252 6.98642 10.0413 7.07453 10.0397C7.16264 10.0381 7.24952 10.0187 7.32998 9.98277C7.41045 9.94683 7.48285 9.89505 7.54287 9.83053L11.0359 5.46428C11.155 5.34046 11.2207 5.17489 11.2191 5.00312C11.2175 4.83134 11.1485 4.66705 11.0271 4.54553H11.0262Z"
                                                fill="#6C6C85"
                                            />
                                        </svg>

                                        <span className="ml-1 text-sm">Verified</span>
                                    </div>
                                </div>

                                <div className="font-semibold text-base">{review.title}</div>
                                <div>
                                    <p
                                        className="text-sm"
                                        style={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {review.details}
                                    </p>
                                </div>
                                <div className="text-[#646565] dark:text-gray-400 text-sm">{review.name}</div>
                            </div>
                        </Link>
                    </CarouselItem>
                ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
        </Carousel>
    );
}
