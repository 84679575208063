export default [
    {
        title: "about-us",
        link: "/about",
    },
    {
        title: "privacy",
        link: "/privacy",
    },
    {
        title: "terms-of-use",
        link: "/terms-of-service",
    },
];
